import $ from 'jquery';

const action = `/contact`;

$(`form[action="${action}"]`).on('submit', async function (event) {
  event.preventDefault();
  const $form = $(this);
  const $formSuccess = $form.find('.js-form-success');
  const $formError = $form.find('.js-form-error');
  const $formGroup = $form.find('.js-form-group');
  const $formSubmit = $form.find('.js-form-submit');
  const formSubmitDefaultText = $formSubmit.text();

  try {
    $formSubmit.prop('disabled', true);
    $formSubmit.text(`Submitting…`);
    const formData = new FormData($form[0]);
    const formSearchParams = new URLSearchParams(formData);
    const response = await fetch(action, {
      method: 'post',
      body: formSearchParams,
    });
    $formSuccess.show();
    $formGroup.hide();
  } catch (error) {
    $formError.show();
    $formSubmit.prop('disabled', false);
    $formSubmit.text(formSubmitDefaultText);
  }
});
