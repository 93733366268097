import { CountUp } from 'countup.js';
import { onIntersection } from './observer';

Array.from(document.querySelectorAll('.js-countup')).forEach((element) => {
  let endval = element.getAttribute('data-countup-endval');
  let options = {};
  if (endval.includes('%')) {
    endval = endval.replace(/\D/g, '');
    options.suffix = '%';
  }
  const countup = new CountUp(element, endval, options);

  // Only support the countup animation if user doesn't want reduced motion.
  if (window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
    // Wait until element is in viewport to start countup animation.
    onIntersection(element, () => {
      if (countup.error) {
        return;
      }
      countup.start();
    });
  }

  // Skip countup animation if user prefers reduced motion
  // (Also allows us to emulate it for screenshot purposes)
  if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
    element.textContent = countup.formatNumber(endval);
  }
});
