import Chartist from 'chartist';
import { onIntersection } from './observer';

const options = {
  height: '300px',
  chartPadding: {
    top: 30,
    left: 15,
  },
  showGrid: false,
  axisX: {
    showGrid: false,
  },
  axisY: {
    showGrid: false,
    labelInterpolationFnc: function skipLabels(value, index) {
      return value % 5 === 0 ? `${value}%` : null;
    },
  },
};

$('.js-chart').each((index, element) => {
  const $chart = $(element);
  const data = $chart.data('chart');
  const total = data.reduce((total, item) => total + item.value, 0);
  const labels = data.map((item) => item.label);
  const values = data.map((item) => Math.round((item.value / total) * 100));

  const chart = new Chartist.Bar(
    element,
    {
      labels: labels,
      series: [values],
    },
    options
  );

  onIntersection(element, () => {
    chart.on('draw', function (data) {
      if (data.type == 'bar') {
        data.element.animate({
          y2: {
            begin: 250 * data.index,
            dur: 1000,
            from: data.y1,
            to: data.y2,
          },
        });
      }
    });
    chart.update();
  });
});
