import $ from 'jquery';

const $window = $(window);
const $masthead = $('.js-masthead');

$window.on('scroll', () => {
  if ($window.scrollTop() > 0) {
    $masthead.addClass('is-scrolled');
  } else {
    $masthead.removeClass('is-scrolled');
  }
});
