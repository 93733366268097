import Gumshoe from 'gumshoejs';

const elementScrollspy = document.querySelector('.js-scrollspy');
const elementScrollspyOffset = document.querySelector('.js-scrollspy-offset');
const elementScrollspyLabel = document.querySelector('.js-scrollspy-label');

if (elementScrollspy) {
  new Gumshoe('.js-scrollspy a', {
    navClass: 'is-active',
    contentClass: 'is-active',
    offset: () => elementScrollspyOffset.getBoundingClientRect().height,
  });

  const defaultLabel = elementScrollspyLabel.textContent;

  document.addEventListener('gumshoeDeactivate', (event) => {
    if (elementScrollspyLabel) {
      elementScrollspyLabel.textContent = defaultLabel;
    }
  });

  document.addEventListener('gumshoeActivate', (event) => {
    if (elementScrollspyLabel) {
      elementScrollspyLabel.textContent = event.detail.link.textContent;
    }
  });
}
