import $ from 'jquery';

const $document = $(document);

$('.js-block').each((index, block) => {
  const $block = $(block);
  const $blockTooltip = $block.find('.js-block-tooltip');
  const $blockButton = $block.find('.js-block-button');
  // Toggle block when clicking either button
  $blockButton.on('click', () => $block.toggleClass('is-toggled'));
  // Close block when clicking outside block tooltip (excl. block button)
  $document.on('click', (event) => {
    if (
      !$(event.target).closest($blockTooltip).length &&
      !$(event.target).is($blockButton)
    ) {
      $block.removeClass('is-toggled');
    }
  });
});
