export function onIntersection(element, callback) {
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          callback();
          observer.unobserve(element);
        }
      });
    },
    { threshold: 1 }
  );
  observer.observe(element);
}

// Add "is-observed" class to ".js-observe" elements when observed.
Array.from(document.querySelectorAll('.js-observe')).forEach((element) =>
  onIntersection(element, () => element.classList.add('is-observed'))
);
