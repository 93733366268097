import $ from 'jquery';

const $document = $(document);

$('.js-navigation').each((index, navigation) => {
  const $navigation = $(navigation);
  const $navigationButton = $navigation.find('.js-navigation-button');
  const $navigationLinks = $navigation.find('.js-navigation-link');

  // Toggle navigation when clicking button.
  $navigationButton.on('click', () => $navigation.toggleClass('is-toggled'));

  // Toggle (close) navigation when clicking link.
  $navigationLinks.on('click', () => $navigation.toggleClass('is-toggled'));

  // Close navigation when clicking outside navigation.
  $document.on('click', (event) => {
    if (!$(event.target).closest($navigation).length) {
      $navigation.removeClass('is-toggled');
    }
  });
});
